<template>
  <v-app
    :light="$theme.light"
    :dark="$theme.dark"
    id="app"
    :style="`background: url(${background})`"
    class="bg-theme"
  >
    <router-view />
    <ModalConfirmAction />
    <ModalLoading />
    <ModalGlobalMessage />
  </v-app>
</template>

<script>
import ModalConfirmAction from "@/apps/shared/components/ModalConfirmAction";
import ModalLoading from "@/apps/shared/components/ModalLoading";
import ModalGlobalMessage from "@/apps/shared/components/ModalGlobalMessage";
import { EventBus } from "./main.js";

export default {
  components: {
    ModalConfirmAction,
    ModalLoading,
    ModalGlobalMessage
  },
  data() {
    return {
      background: 'bg.jpg'
    };
  },
  methods: {
    async getBluetooth() {
      const bluetoothDevices2 = await navigator.bluetooth.requestDevice();
    },
    async requestPort() {
      const port = await navigator.serial.requestPort();
      port.open({ baudRate: 9600 });
    }
  },
  async created() {
    if (location.protocol === 'http:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      // console.log("serial disponível", navigator);
      // const ports = await navigator.serial.();
      // const ports = await navigator.serial.getPorts();
      // const devices = await navigator.mediaDevices.enumerateDevices();
      // const bluetoothDevices = await navigator.bluetooth.getAvailability();
      // let mediadevices = navigator.getUserMedia();
      // console.log("lista de devices", mediadevices);
      // console.log("lista de devices", devices);
      // console.log("lista de ports", ports);
      // console.log("lista de bt", bluetoothDevices);
      // navigator.getMedia(
      //   // permissoes
      //   {
      //     video: true,
      //     audio: true
      //   },
      //   // callbackSucesso
      //   function(localMediaStream) {
      //     var video = document.querySelector(".video");
      //     video.src = window.URL.createObjectURL(localMediaStream);
      //     video.onloadedmetadata = function(e) {
      //       console.log("e", e);
      //       // Faz algo com o vídeo aqui.
      //     };
      //   },
      //   // callbackErro
      //   function(err) {
      //     console.log("O seguinte erro ocorreu: " + err);
      //   }
      // );
    }
  },
  async mounted() {
    const component = this;
    this.handler = function(e) {
      component.$emit("keydown", e);
      // console.log("e", e);
      if (e.key === "Escape") {
        EventBus.$emit("escape", true);
      }
      if (e.key === "ArrowUp") {
        EventBus.$emit("ArrowUp", true);
      }
      if (e.key === "ArrowDown") {
        EventBus.$emit("ArrowDown", true);
      }
      if (e.key === "ArrowRight") {
        EventBus.$emit("ArrowRight", true);
      }
      if (e.key === "ArrowLeft") {
        EventBus.$emit("ArrowLeft", true);
      }
      if (e.key === "Enter") {
        EventBus.$emit("Enter", true);
      }
      //detect ctrl key
      if (e.key === "Control") {
        EventBus.$emit("CtrlDown", true);
      }
    };
    this.handlerUp = function(e) {
      component.$emit("keyup", e);
      //detect ctrl key
      // if (e.key === "Control") {
      //   EventBus.$emit("CtrlUp");
      // }
    };
    window.addEventListener("keydown", this.handler);
    window.addEventListener("keyup", this.handlerUp);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handler);
    window.removeEventListener("keyup", this.handlerUp);
  }
};
</script>

<style>
@media (prefers-color-scheme: dark) {
  body {
    background: #333;
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background: #333;
    color: white;
  }
}
.bg-theme {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.fonteMini {
  font-size: 8pt;
}
.fonte-mini {
  font-size: 8pt;
}
.click {
  cursor: pointer;
}
.item-table {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
}
.bg-table {
  background: #f2f2f2;
}
.column {
  flex-direction: column;
}
.bg_white {
  background: #fff;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
.item-list-title {
  padding: 6px;
  background: #e2e2e2;
  border-radius: 6px;
  margin-bottom: 6px;
}
.inflar {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.inflar-porcentagem {
  display: flex;
  width: 100%;
  height: 100%;
}
.centraliza {
  justify-content: center;
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.linha {
  flex-direction: row;
}
.fonte-default {
  font-family: "Prompt", sans-serif;
}
@font-face {
  font-family: Sui Generis;
  src: url("/fonts/suigenerisrg.otf") format("opentype");
}
.fonte-italica {
  font-style: italic;
}
.fonte-big {
  font-size: 48px;
}
.fonte-medium {
  font-size: 36px;
}
.fonte-little {
  font-size: 24px;
}
.fonte-mini {
  font-size: 16px;
}
.fonte-micro {
  font-size: 12px;
}
.fonte {
  font-family: "Prompt", sans-serif;
}
.font-color {
  color: #363636;
}
.font-color--green {
  color: #47cf72;
}
.font-color--secondary {
  color: #1173ff;
}
.fonte-bold {
  font-weight: 600;
}
.item-contained {
  border-radius: 6px;
  padding: 12px;
  background: #333;
}
.expande-vh-vertical {
  height: 100vh;
}
/* .menuAtivoHome {
  background: linear-gradient(to right, #03A9F4, #56ccf2);
  border-radius: 1px;
  box-shadow: 0 3px 5px 2px rgba(90, 62, 2, 0.2);
}
.bg-card-dash {
  background: linear-gradient(#56ccf2, #03A9F4);
} */
.menuAtivoHome {
  background: linear-gradient(to right, #1033f9, #5d74f5);
  border-radius: 1px;
}
.menuAtivoHomeGold {
  background: #f12711; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f12711,
    #f5af19
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f12711, #f5af19);
}
.cardMesa {
  background: #1173ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #1173ff,
    #010a33
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1173ff, #010c3f);
}
.bg-card-dash {
  background: linear-gradient(#1173ff, #0020ab);
}
.item {
  height: 40px;
}
.vertical-60 {
  min-height: 60vh;
}
.vitrify {
  min-height: 100vh;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);
}
.vitrify-content {
  background: rgba(215, 215, 215, 0.1) !important;
  backdrop-filter: blur(10px) !important;
}
.vitrify-mini {
  background: rgba(215, 215, 215, 0.1) !important;
}
.vitro {
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(10px);
}
.default-font-color {
  color: #1173ff;
}
.secondary-color {
  color: #e1673c;
}
.fonteMini {
  font-size: 8pt;
}
.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
  padding-right: 6px;
  padding-left: 6px;
}
.windows-style-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #e0e0e0;
  border-top: none;
  background: #f2f2f2;
  padding-right: 6px;
  padding-left: 6px;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.windows-style-content::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.windows-style-content::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.windows-style-content::-webkit-scrollbar-thumb {
  background-color: #707172; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.cool-shaddow {
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}
.not-selectable {
  user-select: none;
  -webkit-user-select: none; /* Para compatibilidade com navegadores baseados em WebKit, como o Safari */
  -moz-user-select: none; /* Para compatibilidade com navegadores baseados em Gecko, como o Firefox */
  -ms-user-select: none; /* Para compatibilidade com o Internet Explorer */
}
.text-no-wrap {
  white-space: nowrap;
}
.signed-gradient {
  background-image: linear-gradient(to right, rgb(140, 85, 195), steelblue, rgb(52, 197, 182));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: black;
  user-select: none;
  -webkit-user-select: none; /* Para compatibilidade com navegadores baseados em WebKit, como o Safari */
  -moz-user-select: none; /* Para compatibilidade com navegadores baseados em Gecko, como o Firefox */
  -ms-user-select: none; /* Para compatibilidade com o Internet Explorer */
}
</style>
