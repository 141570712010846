import http from "@/apps/shared/baseService/config";

const base = {
  rota: "withdraw",
  nome: "saques"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list-withdraw-requests`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_withdraws: ({ commit, dispatch, getters }) => {
      if (localStorage.withdraws) {
        commit("set_withdraws", JSON.parse(localStorage.withdraws));
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_withdraws_filtros)
        .then(res => {
          localStorage.withdraws = JSON.stringify(res.data);
          dispatch("disableLoading");
          commit("set_withdraws", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_withdraw: ({ commit, dispatch, getters }, payload) => {
      dispatch("enableLoading");
      let data = payload ? payload : getters.get_withdraw;
      service
        .atualizar(data)
        .then(res => {
          dispatch("disableLoading");
          commit("set_withdraw", res.data);
          if (payload.from_profile) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...res.data,
                tenant: payload.tenant,
                permissao: payload.permissao
              })
            );
            commit("setLoggedUser", {
              ...res.data,
              tenant: payload.tenant,
              permissao: payload.permissao
            });
          }
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_withdraw: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_withdraw, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_withdraw", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
          commit("set_modal_view_withdraw", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_withdraw: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_withdraw: ({ commit }, payload) => {
      if (payload) {
        let data = payload;
        if (!data.professional_record) {
          data.professional_record = {
            advice: "",
            number: "",
            uf: ""
          };
        }
        if (!data.address) {  
          data.address = {
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
            zipcode: ""
          };
        }
        commit("set_withdraw", data);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_withdraw", true);
    },
    fecha_modal_view_withdraw: ({ commit }) => {
      commit("set_withdraw", {});
      commit("setLinks", []);
      commit("set_modal_view_withdraw", false);
    }
  },
  state: {
    withdraw: {},
    withdraws: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    withdraws_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_withdraw: false
  },
  mutations: {
    set_withdraw: (state, payload) => (state.withdraw = payload),
    set_withdraws: (state, payload) => (state.withdraws = payload),
    set_modal_view_withdraw: (state, payload) =>
      (state.modal_view_withdraw = payload)
  },
  getters: {
    get_withdraw: state => state.withdraw,
    get_withdraws: state => state.withdraws,
    get_modal_view_withdraw: state => state.modal_view_withdraw,
    get_withdraws_filtros: state => state.withdraws_filtros
  }
};

const routes = [
  {
    path: `/solicitacoes-de-saque`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
