<template>
  <v-dialog
    v-model="opened"
    width="700"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-flex xs12>
      <v-card style="min-height: 100vh;" :loading="loading" class="pa-3">
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12>
              <v-form ref="formMinhaLoja">
                <v-flex xs12>
                  <div class="fonte expande-horizontal">
                    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
                        <h1 style="font-weight: 300;" class="fonte black--text">
                          {{ $helper.formataSaldo((subscription.plan_id ? subscription.plan_id.value_cents : 0 ) + (subscription.dependents * 20)) }}
                        </h1>
                        <h3 style="font-weight: 300;" class="fonte grey--text">
                          Plano {{ subscription.plan_id ? subscription.plan_id.name : '' }}
                        </h3>
                        <span class="fonte green--text"
                          >Sua assinatura está
                          {{ subscription.current_status | filterStatusSubscription }}</span
                        >
                        <span v-if="warning" class="fonte red--text">
                          {{ message }}
                        </span>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn v-if="!warning" @click="close" icon>
                        <v-icon color="deep-purple" size="30">mdi-close</v-icon>
                      </v-btn>
                  </div>
                  <v-divider></v-divider>
                  <h3 class="fonte mt-3 text-center" style="font-weight: 500;">Confira as faturas em aberto</h3>
                  <div class="pa-6 px-0 centraliza expande-horizontal wrap">
                    <v-flex xs12>
                      <v-list dense class="pa-0 ma-0 fonte expande-horizontal column">
                        <v-list-item
                          class="item-list-charge mb-3"
                          v-for="charge in subscription.asaas_payments"
                          :key="charge._id"
                          v-show="charge.status !== 'RECEIVED' && charge.status !== 'RECEIVED_IN_CASH'"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="black--text font-weight-bold">
                              {{ $helper.formataSaldo(charge.value) }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text">
                              Vencimento: {{ $moment(charge.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-btn small dark class="fonte text-capitalize" @click="openPaymentLink(charge)" color="green">Abrir Pagamento
                                <v-icon size="21">
                                  mdi-open-in-new
                                </v-icon>
                              </v-btn>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </div>

                  <h3 class="fonte mt-3 text-center" style="font-weight: 500;">Veja seus dependentes</h3>
                  <div class="expande-horizontal">
                    <v-list>
                      <template class="px-3">
                        <v-list-item style="background: #f2f2f2;" class="fonte mb-3">
                          <v-list-item-content>
                            <v-list-item-title>
                              Link de ingresso  de dependente
                            </v-list-item-title>
                            <span class="link-seller">
                              https://app.idemsaude.com.br/#/cadastro?t={{ subscription._id }}
                            </span>
                            <v-btn :color="$theme.primary" dark class="mt-2" @click="copyLinkDependent">Copiar</v-btn>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <v-btn small color="blue" dark @click="copyLink">copiar</v-btn>
                          </v-list-item-action> -->
                        </v-list-item>
                        <v-list-item style="background: #f2f2f2;" class="fonte mb-3">
                          <v-list-item-content>
                            <v-list-item-title>
                              Ocupação de dependentes
                            </v-list-item-title>
                            <span class="link-seller fonteMini">
                              {{ subscription.dependent_list ? subscription.dependent_list.length : 0 }} de {{ subscription.dependents }}  
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-for="(dependent, i) in subscription.dependent_list">
                        <v-list-item :key="dependent._id" class="px-3">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ dependent.nome }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ dependent.cpf }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="i"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
          </div>
        </v-flex>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import LottieAnimation from "lottie-web-vue";

export default {
  data() {
    return {
      opened: false,
      subscription: {
        methods: "",
        plan_id: {}
      },
      message: "",
      warning: false,
      loading: false
    };
  },
  components: {
    VueCropper,
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    methodSelected() {
      return this.subscription.method || "";
    },
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = this.$moment(expires).diff(this.$moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return this.$moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  filters: {
    filterStatusSubscription(val) {
      switch (val) {
        case "trial":
          return "ativa";
        case "active":
          return "ativa";
        default:
          return "Pendente";
      }
    }
  },
  methods: {
    ...mapActions(['createGlobalMessage']),
    copyLinkDependent() {
      navigator.clipboard.writeText(`https://app.idemsaude.com.br/#/cadastro?t=${this.getLoggedUser._id}`).then(() => {
        this.createGlobalMessage({
          message: "O link copiado para sua área de transferência!",
          type: "success",
          timeout: 5000
        });
      }).catch(err => {
        console.error('Erro ao copiar texto: ', err);
      });
    },
    openPaymentLink(charge) {
      window.open(charge.invoiceUrl, "_blank");
    },
     verifySub() {
      this.loading = true;
      this.$run('subscription/view-my-subscription')
        .then(res => {
          this.subscription = res.data;
          this.loading = false;
          if (res.data.current_status == 'overdue' || res.data.current_status == 'canceled' ) {
            this.message = 'Sua assinatura está pendente, por favor, realize o pagamento para ativar sua assinatura.'
            this.opened = true;
            this.warning = true;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err)
        });
    },
    open() {
      this.opened = true;
      // this.loading = true;
      // this.$run('subscription/view-my-subscription')
      //   .then(res => {
      //     this.subscription = res.data;
      //     this.loading = false;
      //   })
      //   .catch(err => {
      //     this.loading = false;
      //     console.log(err)
      //   });
    },
    saveSubscription() {
      this.$run('subscription/activate-my-subscription', this.subscription)
        .then(res => {
        })
        .catch(err => console.log(err));
    },
    close() {
      this.opened = false;
    },
    selectPaymentForm(method) {
      this.subscription = {
        ...this.subscription,
        method
      };
    }
  }
}
</script>

<style>
.store-layout {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  cursor: pointer;
}

.store-cover {
  width: 100%;
  min-height: 220px;
  border-radius: 6px;
}

.profile-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.store-profile {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-list-charge {
  background: #e6e4e4 !important;
  border-radius: 6px;
  /* box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1); */
}
.change-method-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
}
.method-selected {
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  background: #9206b5 !important;
}
.change-method-icon-container {
  cursor: pointer;
}
</style>
