import http from "@/apps/shared/baseService/config";

const base = {
  rota: "clientes",
  nome: "Clientes"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_clientes: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("clientes")) {
        commit("set_clientes", JSON.parse(localStorage.getItem("clientes")));
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_clientes_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem("clientes", JSON.stringify(res.data));
          commit("set_clientes", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_cliente: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_cliente, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_cliente", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_clientes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_cliente: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_cliente, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_cliente", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_clientes");
          commit("set_modal_view_cliente", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_cliente: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_clientes");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_cliente: ({ commit }, payload) => {
      if (payload) {
        commit("set_cliente", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_cliente", true);
    },
    fecha_modal_view_cliente: ({ commit }) => {
      commit("set_cliente", {});
      commit("setLinks", []);
      commit("set_modal_view_cliente", false);
    }
  },
  state: {
    cliente: {},
    clientes: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    clientes_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_cliente: false
  },
  mutations: {
    set_cliente: (state, payload) => (state.cliente = payload),
    set_clientes: (state, payload) => (state.clientes = payload),
    set_modal_view_cliente: (state, payload) =>
      (state.modal_view_cliente = payload)
  },
  getters: {
    get_cliente: state => state.cliente,
    get_clientes: state => state.clientes,
    get_modal_view_cliente: state => state.modal_view_cliente,
    get_clientes_filtros: state => state.clientes_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
