const routes = [
  {
    path: `/configurar-loja`,
    component: () => import("./views/Main.vue"),
    name: "Ajustes da Loja"
  },
  {
    path: `/assinatura`,
    component: () => import("./views/MyStoreSubscription.vue"),
    name: "Assinatura"
  }
];

export default {
  routes
};
