export const auth_routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../Main.vue")
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: () => import("../Signup.vue")
  },
  {
    path: "/cadastro-medico",
    name: "Cadastro Médico",
    component: () => import("../SignupDoctor.vue")
  },
  {
    path: "/recover-pass/:token",
    props: true,
    name: "Recuperação de Senha",
    component: () => import("../ForgotPasswordFire.vue")
  },
  {
    path: "/activate-account/:id",
    props: true,
    name: "Ative sua conta",
    component: () => import("../ActivateAccount.vue")
  }
];

export default {};
