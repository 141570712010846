export const dashboard_routes = [
  {
    path: "/dashboard",
    component: () => import("../Main.vue"),
    name: "Dashboard"
  },
  {
    path: "/medico",
    component: () => import("../MainDoctor.vue"),
    name: "Médico"
  },
  {
    path: "/inicio-vendedor",
    component: () => import("../MainSeller.vue"),
    name: "Vendedor"
  },
];

export default {};
