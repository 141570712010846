import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { dashboard_routes } from "./apps/dashboard/router";
import { logged_user_routes } from "./apps/2#userLogged/router";
import { auth_routes } from "./apps/2#auth/router";
import Permissao from "./apps/1#Permissoes/meta";
import Funcionario from "./apps/9#Funcionarios/meta";
import Caixa from "./apps/27#Caixas/meta";
import MenuOnline from "./apps/MenuOnline/meta";
import Cliente from "./apps/44#Clientes/meta";
import ConfigurarLoja from "./apps/ConfigurarLoja/meta";
import Fornecedor from "./apps/Fornecedor/meta";
import Relatorio from "./apps/Relatorio/meta";
import GestorDePedido from "./apps/GestorDePedido/meta";
import Lojas from "./apps/0#Lojas/meta";
import Insumo from "./apps/Insumos/meta";
import SignedTransaction from "./apps/SignedTransaction/meta";
import Invoices from "./apps/46#Invoices/meta";
import Checkout from "./apps/Checkout/meta";
import MySubscription from "./apps/MySubscription/meta";
import ExternalMovimentation from "./apps/ExternalMovimentation/meta";
import Withdraw from "./apps/Withdraw/meta";
import DoctorWithdraw from "./apps/DoctorWithdraw/meta";
import Seller from "./apps/Seller/meta"

const routes = [
  ...dashboard_routes,
  ...logged_user_routes,
  ...auth_routes,
  ...Permissao.routes,
  ...Funcionario.routes,
  ...Caixa.routes,
  ...MenuOnline.routes,
  ...Cliente.routes,
  ...ConfigurarLoja.routes,
  ...Fornecedor.routes,
  ...Relatorio.routes,
  ...GestorDePedido.routes,
  ...Lojas.routes,
  ...Insumo.routes,
  ...SignedTransaction.routes,
  ...Invoices.routes,
  ...Checkout.routes,
  ...MySubscription.routes,
  ...ExternalMovimentation.routes,
  ...DoctorWithdraw.routes,
  ...Withdraw.routes,
  ...Seller.routes
];

export const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
